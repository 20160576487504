import { css } from '@emotion/react'

const GlobalStyles = css`
  body,
  html {
    width: 100%;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden;
    height: 100%;
  }
  #___gatsby {
    height: 100%;
  }
  #gatsby-focus-wrapper {
    height: 100%;
  }
  main {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  html {
    font-family: 'Lato', sans-serif;
    box-sizing: border-box;
    font-size: 62.5%;
  }
  * {
    margin: 0;
    padding: 0;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  a:link {
    text-decoration: none;
  }

  a:visited {
    text-decoration: none;
  }

  a:hover {
    text-decoration: none;
  }

  a:active {
    text-decoration: none;
  }
`
export default GlobalStyles
