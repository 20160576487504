const EXTRA_SMALL_BREAKPOINT = 350
const SMALL_BREAKPOINT = 576
const MEDIUM_BREAKPOINT = 768
const LARGE_BREAKPOINT = 992
const EXTRA_LARGE_BREAKPOINT = 1200
const EXTRA_EXTRA_LARGE_BREAKPOINT = 1600
const MAX_BREAKPOINT = 1900

export const mq = {
  xsm: `@media (max-width: ${EXTRA_SMALL_BREAKPOINT}px)`,
  sm: `@media (max-width: ${SMALL_BREAKPOINT}px)`,
  md: `@media (max-width: ${MEDIUM_BREAKPOINT}px)`,
  lg: `@media (max-width: ${LARGE_BREAKPOINT}px)`,
  xl: `@media (max-width: ${EXTRA_LARGE_BREAKPOINT}px)`,
  xxl: `@media (max-width: ${EXTRA_EXTRA_LARGE_BREAKPOINT}px)`,
  max: `@media (max-width: ${MAX_BREAKPOINT}px)`,
}

export default mq
